import {
  DailyReportResponse,
  TagResponse,
  UserResponse,
  ChannelResponse,
  StatsCompletion,
  TeamStatsResponse,
  StatsByTag,
} from 'remonade-api/lib';

import {
  getIsDone,
  calcTotalHours,
  calcDoneHours,
} from './TaskService';

import {
  CombineBarChartItem,
  BarChartItem,
  PieChartData,
  PlotData,
  SelectOptionItem,
} from '@/model/types';

import dayjs from 'dayjs';
import { hours } from './ModelService';

interface PieChartColor {
  main: string;
  sub: string;
}

const pieColors: PieChartColor[] = [
  {
    main: '#00AF74',
    sub: '#BAEBDA',
  },
  {
    main: '#D00019',
    sub: '#F3C2C8',
  },
  {
    main: '#0F23D3',
    sub: '#CACDEA',
  },
  {
    main: '#EB8E03',
    sub: '#F3E5D0',
  },
  {
    main: '#23A9AF',
    sub: '#D0EFF0',
  },
  {
    main: '#CC037B',
    sub: '#F1CBE2',
  },
  {
    main: '#5B03CC',
    sub: '#DDCEF0',
  },
  {
    main: '#ECD400',
    sub: '#F0EDCD',
  },
  {
    main: '#87D3BA',
    sub: '#E8F3F0',
  },
  {
    main: '#E07B87',
    sub: '#F7DEE1',
  },
  {
    main: '#818AD9',
    sub: '#E0E2F8',
  },
  {
    main: '#EAC48B',
    sub: '#F9EEDF',
  },
  {
    main: '#99D6D9',
    sub: '#E4F5F5',
  },
  {
    main: '#DC9FC3',
    sub: '#F6E6F0',
  },
  {
    main: '#BEA9DA',
    sub: '#F1EAFB',
  },
  {
    main: '#EFE79C',
    sub: '#FBF9E5',
  },
];

export const calcTotalTaskCount = (reports: DailyReportResponse[]) => {
  const rootReports = reports.filter((r) => r.parentId === null);
  return rootReports.length;
};

export const calcDoneTaskCount = (reports: DailyReportResponse[]) => {
  const rootReports = reports.filter((r) => r.parentId === null);
  const doneReports = rootReports.filter((r) => {
    return getIsDone(r, reports);
  });
  return doneReports.length;
};

export const calcDoneRate = (reports: DailyReportResponse[]) => {
  const totalHours = calcTotalHours(reports);
  if (totalHours === 0) {
    return 0;
  }
  const doneHours = calcDoneHours(reports);
  return doneHours / totalHours;
};

export const calcDoneRateWithStats = (reports: DailyReportResponse[], completions: StatsCompletion[]) => {
  const statsTotalHours = completions.reduce((hour: number, completion: StatsCompletion) => {
    return hour + completion.totalHour;
  }, 0);
  const totalHours = calcTotalHours(reports) + statsTotalHours;
  if (totalHours === 0) {
    return 0;
  }

  const doneHours = calcDoneHours(reports);
  const statsDoneHours = completions.reduce((hour: number, completion: StatsCompletion) => {
    return hour + completion.doneHour;
  }, 0);
  return doneHours / totalHours;
};

export const calcAveHours = (reports: DailyReportResponse[]) => {
  const rootReports = reports.filter((r) => r.parentId === null);
  const totalHours = calcTotalHours(reports);
  if (totalHours === 0) {
    return 0;
  }
  return totalHours / rootReports.length;
};

export const getCombineChartItems = (reports: DailyReportResponse[], startDate: string, endDate: string): CombineBarChartItem[] => {
  const fills = ['#00AF74', '#DDE6E6'];

  const dateDiff = dayjs(endDate).diff(dayjs(startDate), 'day') + 1;
  let maxHour = 0;
  const intermed: Array<{ date: string, reports: DailyReportResponse[] }> = [...Array(dateDiff)].map((emp, i) => {
    const date = dayjs(startDate).add(i, 'day');
    const dateString = date.format('YYYY-MM-DD');
    const dateReports = reports.filter((r) => r.date === dateString);
    const totalHours = calcTotalHours(dateReports);
    if (totalHours > maxHour) {
      maxHour = totalHours;
    }
    return {
      date: date.format('MM/DD'),
      reports: dateReports,
    };
  });
  maxHour = maxHour + 0.01;

  return intermed.map((inter) => {
    if (inter.reports.length === 0) {
      return {
        rates: [0, 0],
        fills,
        contentLabels: [],
        label: inter.date,
      };
    } else {
      const thisTotalHours = calcTotalHours(inter.reports);
      const thisDoneHours = calcDoneHours(inter.reports);
      const thisNotDoneHours = thisTotalHours - thisDoneHours;
      const doneRatio = thisDoneHours / maxHour;
      const notDoneRatio = thisNotDoneHours / maxHour;
      return {
        rates: [doneRatio, notDoneRatio],
        fills,
        contentLabels: [],
        label: inter.date,
      };
    }
  });
};

export const getDoneRateChartData = (reports: DailyReportResponse[], startDate: string, endDate: string): BarChartItem[] => {
  const dateDiff = dayjs(endDate).diff(dayjs(startDate), 'day') + 1;
  return [...Array(dateDiff)].map((emp, i) => {
    const date = dayjs(startDate).add(i, 'day');
    const dateString = date.format('YYYY-MM-DD');
    const dateReports = reports.filter((r) => r.date === dateString);
    if (dateReports.length === 0) {
      return {
        rate: 0,
        contentLabels: [],
        label: date.format('MM/DD'),
      };
    }
    const totalHours = calcTotalHours(dateReports);
    const doneHours = calcDoneHours(dateReports);

    if (totalHours === 0) {
      return {
        rate: 0,
        contentLabels: [],
        label: date.format('MM/DD'),
      };
    }

    return {
      rate: doneHours / totalHours,
      contentLabels: [],
      label: date.format('MM/DD'),
    };
  });
};

export const getTodayStatsSummary = (stats: StatsCompletion[], todayReports: DailyReportResponse[], startDate: string, endDate: string): BarChartItem[] => {
  const dateDiff = dayjs(endDate).diff(dayjs(startDate), 'day') + 1;
  const today = dayjs().format('YYYY-MM-DD');
  return [...Array(dateDiff)].map((emp, i) => {
    const date = dayjs(startDate).add(i, 'day');
    const dateString = date.format('YYYY-MM-DD');

    if (dateString === today) {
      const totalHours = calcTotalHours(todayReports);
      const doneHours = calcDoneHours(todayReports);
      return {
        rate: totalHours > 0 ? doneHours / totalHours : 0,
        contentLabels: [],
        label: date.format('MM/DD'),
      };
    }

    const dateStats = stats.find((s) => s.date === dateString);
    if (!dateStats) {
      return {
        rate: 0,
        contentLabels: [],
        label: date.format('MM/DD'),
      };
    }
    return {
      rate: dateStats.completion,
      contentLabels: [],
      label: date.format('MM/DD'),
    };
  });
};

export const getPieChartData = (reports: DailyReportResponse[], tags: TagResponse[]): PieChartData[] => {
  const totalHours = calcTotalHours(reports);
  const ts = [...tags];
  const data: PieChartData[] = ts.map((tag) => {
    const tagRootReports = reports.filter((r) => r.parentId === null && r.tag === tag.tagId);
    const childReports = reports.filter((r) => r.parentId !== null);
    const possibleReports = tagRootReports.concat(childReports);
    if (tagRootReports.length > 0) {
      const tagTotalHours = calcTotalHours(possibleReports);
      const tagDoneHours = calcDoneHours(possibleReports);
      const tagNotDoneHours = tagTotalHours - tagDoneHours;
      const tasksRate = tagTotalHours / totalHours;
      if (totalHours === 0) {
        return {
          rate: 0,
          subRate: 0,
          color: '',
          subColor: '',
        };
      }
      return {
        rate: tagDoneHours / totalHours,
        subRate: tagNotDoneHours / totalHours,
        color: '#00AF74',
        subColor: '#f0fbf4',
        data: {
          tagId: tag.tagId,
          tasksRate,
          taskHours: tagTotalHours,
          doneRate: (tagDoneHours / tagTotalHours),
        },
      };
    }
    return {
      rate: 0,
      subRate: 0,
      color: '',
      subColor: '',
    };
  }).filter((pd) => (pd.rate > 0) || (pd.subRate > 0) ).sort((pd1, pd2) => {
    const weight1 = pd1.rate + pd1.subRate;
    const weight2 = pd2.rate + pd2.subRate;
    if (weight1 > weight2) {
      return -1;
    }
    if (weight1 < weight2) {
      return 1;
    }
    return 0;
  }).map((pd, i) => {
    const colorLength = pieColors.length;
    const colorIndex = i % colorLength;
    pd.color = pieColors[colorIndex].main;
    pd.subColor = pieColors[colorIndex].sub;
    return pd;
  });

  // calc no tags
  const notagReports = reports.filter((r) => r.tag === null);
  const notagHours = calcTotalHours(notagReports);
  if (notagHours > 0) {
    const notagDoneHours = calcDoneHours(notagReports);
    const notagNotDoneHours = notagHours - notagDoneHours;
    const notagRate = notagHours / totalHours;
    if (totalHours === 0) {
      data.push({
        rate: 0,
        subRate: 0,
        color: '#85928F',
        subColor: '#EEF2F4',
        data: {
          tagId: null,
          taskRate: 0,
          taskHours: notagHours,
          doneRate: (notagDoneHours / notagHours),
        },
      });
    } else {
      data.push({
        rate: notagDoneHours / totalHours,
        subRate: notagNotDoneHours / totalHours,
        color: '#85928F',
        subColor: '#EEF2F4',
        data: {
          tagId: null,
          taskRate: notagRate,
          taskHours: notagHours,
          doneRate: (notagDoneHours / notagHours),
        },
      });
    }
  }
  return data;
};

export const getPlotData = (teamUsers: UserResponse[], reports: DailyReportResponse[]): PlotData[] => {
  let maxHour = 0;
  const intermed: Array<{
    user: UserResponse,
    totalHours: number,
    doneHours: number,
  }> = teamUsers.map((user) => {
    const userReports = reports.filter((r) => r.userId === user.userId);
    const totalHours = calcTotalHours(userReports);
    const doneHours = calcDoneHours(userReports);
    if (totalHours > maxHour) {
      maxHour = totalHours;
    }
    return {
      user,
      totalHours,
      doneHours,
    };
  });
  return intermed.map((inter) => {
    return {
      x: inter.totalHours / maxHour,
      y: (inter.doneHours / inter.totalHours) || 0,
      data: { user: inter.user },
    };
  });
};


/**
 * For google charts
 */
export const getCompletionAndTaskHourData = (
  targetReports: DailyReportResponse[],
  startDate: string,
  endDate: string,
  comparisonReports?: DailyReportResponse[],
  comparisonStartDate?: string,
  comparisonEndDate?: string,
) => {
  const dateDiff = dayjs(endDate).diff(dayjs(startDate), 'day') + 1;

  let maxHour = 0;

  const data = [...Array(dateDiff)].map((emp, i) => {
    const date = dayjs(startDate).add(i, 'day').format('YYYY-MM-DD');
    const reports = targetReports.filter((report) => report.date === date);
    const hour = calcTotalHours(reports);

    if (hour > maxHour) {
      maxHour = hour;
    }

    const completion = calcDoneRate(reports);

    return [dayjs(date).format('MM/DD'), hour, completion * 100];
  });

  const result: Array<Array<string | number | object>> = [
    ['Date', 'Hour', 'Completion'],
  ];
  data.forEach((d) => result.push(d));

  return {
    data: result,
    options: {
      height: 300,
      vAxis: {
        0: {
          title: 'Hour',
          maxValue: maxHour,
        },
        1: {
          title: 'Completion',
          maxValue: 100,
          minValue: 0,
          format: 'percent',
        },
      },
      hAxis: {
        title: 'Date',
      },
      seriesType: 'bars',
      colors: ['#cfd7d5'],
      series: {
        1: {
          type: 'line',
          lineWidth: 4,
          color: '#00af74',
          targetAxisIndex: 1,
        },
      },
    },
  };
};

export const getCompletionAndTaskHourDataWithStats = (
  stats: StatsCompletion[],
  startDate: string,
  endDate: string,
  comparisonReports?: DailyReportResponse[],
  comparisonStartDate?: string,
  comparisonEndDate?: string,
) => {
  const dateDiff = dayjs(endDate).diff(dayjs(startDate), 'day') + 1;

  let maxHour = 0;

  const data = [...Array(dateDiff)].map((emp, i) => {
    const date = dayjs(startDate).add(i, 'day').format('YYYY-MM-DD');
    const targetStats = stats.filter((s) => s.date === date);
    const data: { doneHour: number; totalHour: number; maxHour: number } = targetStats.reduce((r, s) => {
      return {
        doneHour: r.doneHour + s.doneHour,
        totalHour: r.totalHour + s.totalHour,
        maxHour: r.maxHour > s.totalHour ? r.maxHour : s.totalHour,
      };
    }, { doneHour: 0, totalHour: 0, maxHour: 0 });

    if (data.maxHour > maxHour) {
      maxHour = data.maxHour;
    }

    const completion = data.doneHour / data.totalHour;

    return [dayjs(date).format('MM/DD'), data.totalHour, completion * 100];
  });

  const result: Array<Array<string | number | object>> = [
    ['Date', 'Hour', 'Completion'],
  ];
  data.forEach((d) => result.push(d));

  return {
    data: result,
    options: {
      height: 300,
      vAxis: {
        0: {
          title: 'Hour',
          maxValue: maxHour,
        },
        1: {
          title: 'Completion',
          maxValue: 100,
          minValue: 0,
          format: 'percent',
        },
      },
      hAxis: {
        title: 'Date',
      },
      seriesType: 'bars',
      colors: ['#cfd7d5'],
      series: {
        1: {
          type: 'line',
          lineWidth: 4,
          color: '#00af74',
          targetAxisIndex: 1,
        },
      },
    },
  };
};

export const getTasksByTagData = (targetReports: DailyReportResponse[], tags: SelectOptionItem[]) => {
  const data = tags.map((tag) => {
    const reports = targetReports;
    const tagReports = reports.filter((r) => r.tag === tag.id);
    const totalHours = calcTotalHours(tagReports);
    const completedHours = calcDoneHours(tagReports);
    const notCompletedHours = totalHours - completedHours;
    return {
      tag: tag.label,
      color: tag.color || '#00af74',
      complete: completedHours,
      notComplete: notCompletedHours,
    };
  });

  const result: Array<Array<string | number | object>> = [
    ['Tag', 'Completed', { role: 'style' }, 'Not completed', { role: 'style' }],
  ];

  data.forEach((d) => {
    if (d.complete > 0 || d.notComplete > 0) {
      result.push([d.tag, d.complete, `color: ${d.color}`, d.notComplete, `color: #cfd7d5`]);
    }
  });

  return {
    data: result,
    options: {
      title : 'Tasks by tag',
      legend: { position: 'top', maxLines: 3 },
      bar: { groupWidth: '75%' },
      colors: [ '#00af74', '#cfd7d5' ],
      isStacked: true,
      height: result.length * 60,
    },
  };
};

export const getTasksByTagDataWithStats = (stats: StatsByTag[], tags: SelectOptionItem[]) => {
  const data = tags.map((tag) => {
    const targetStats = stats.filter((s) => s.tagId === tag.id);
    const totalHours = targetStats.reduce((total, s) => {
      return total + (s.totalHour || 0);
    }, 0);
    const doneHours = targetStats.reduce((total, s) => {
      return total + (s.doneHour || 0);
    }, 0);
    const notCompletedHours = totalHours - doneHours;
    const label = tag.label;
    const color = tag.color || '#00af74';
    return {
      tag: label,
      color,
      complete: doneHours,
      notComplete: notCompletedHours,
    };
  }).sort((obj1, obj2) => obj2.tag > obj1.tag ? 1 : -1);

  const result: Array<Array<string | number | object>> = [
    ['Tag', 'Completed', { role: 'style' }, 'Not completed', { role: 'style' }],
  ];

  data.forEach((d) => {
    if (d.complete > 0 || d.notComplete > 0) {
      result.push([d.tag, d.complete, `color: ${d.color}`, d.notComplete, `color: #cfd7d5`]);
    }
  });

  return {
    data: result,
    options: {
      title : 'Tasks by tag',
      legend: { position: 'top', maxLines: 3 },
      bar: { groupWidth: '75%' },
      colors: [ '#00af74', '#cfd7d5' ],
      isStacked: true,
      height: result.length * 60,
    },
  };
};

export const getCompletionByTagData = (
  tasksByTag: Array<{ tag: SelectOptionItem, reports: DailyReportResponse[] }>,
  startDate: string,
  endDate: string,
  hiddenTagIds?: string[],
) => {
  let targets = tasksByTag.filter((tbt) => tbt.reports.length > 0); // not empty

  if (hiddenTagIds) {
    targets = targets.filter((target) => {
      const find = hiddenTagIds.find((id) => target.tag.id === id);
      if (find) {
        return false;
      }
      return true;
    });
  }

  const header: Array<string | number | { role: string }> = ['Date'];

  const dateDiff = dayjs(endDate).diff(dayjs(startDate), 'day') + 1;

  const data = [...Array(dateDiff)].map((emp, i) => {
    const date = dayjs(startDate).add(i, 'day').format('YYYY-MM-DD');
    const row: Array<string | number> = [dayjs(date).format('MM/DD')];

    targets.forEach((taskByTag) => {
      const tagTasks = taskByTag.reports.filter((task) => task.date === date);
      const tagCompletion = calcDoneRate(tagTasks) * 100;
      row.push(tagCompletion);
      row.push(`color: ${taskByTag.tag.color}`);

      if (i === 0) {
        header.push(taskByTag.tag.label);
        header.push({ role: 'style' });
      }
    });

    return row;
  });

  const result: Array<Array<string | number | object>> = [];
  result.push(header);

  data.forEach((d) => result.push(d));

  return {
    data: result,
    options: {
      legend: {
        position: 'bottom',
        textStyle: {
          fontSize: 12,
        },
      },
      vAxis: {
        0: {
          title: 'Completion',
          maxValue: 100,
          minValue: 0,
          format: 'percent',
        },
      },
      hAxis: {
        title: 'Date',
      },
      height: 300,
    },
  };
};

export const getTasksByDayData = (targetReports: DailyReportResponse[]) => {
  const reports = targetReports;
  const data = [...Array(7)].map((emp, i) => {
    const dayReports = reports.filter((report) => dayjs(report.date).day() === i);
    const totalHours = calcTotalHours(dayReports);
    const completedHours = calcDoneHours(dayReports);
    const notCompletedHours = totalHours - completedHours;
    return {
      complete: completedHours,
      notComplete: notCompletedHours,
    };
  });

  const result: Array<Array<string | number | object>> = [
    ['Day', 'Completed', 'Not completed'],
  ];

  data.forEach((d, i) => {
    if (i === 0) {
      result.push(['Sun', d.complete, d.notComplete]);
    } else if (i === 1) {
      result.push(['Mon', d.complete, d.notComplete]);
    } else if (i === 2) {
      result.push(['Tue', d.complete, d.notComplete]);
    } else if (i === 3) {
      result.push(['Wed', d.complete, d.notComplete]);
    } else if (i === 4) {
      result.push(['Thr', d.complete, d.notComplete]);
    } else if (i === 5) {
      result.push(['Fri', d.complete, d.notComplete]);
    } else if (i === 6) {
      result.push(['Sat', d.complete, d.notComplete]);
    }
  });

  // #00af74 #cfd7d5

  return {
    data: result,
    options: {
      legend: { position: 'top', maxLines: 3 },
      isStacked: true,
      colors: [ '#00af74', '#cfd7d5' ],
      height: 300,
    },
  };
};

export const getTasksByDayDataWithStats = (stats: StatsCompletion[]) => {
  const data = [...Array(7)].map((emp, i) => {
    const dayStats = stats.filter((s) => dayjs(s.date).day() === i);
    const totalHours = dayStats.reduce((total, s) => {
      return s.totalHour + total;
    }, 0);
    const completedHours = dayStats.reduce((total, s) => {
      return s.doneHour + total;
    }, 0);
    const notCompletedHours = totalHours - completedHours;
    return {
      complete: completedHours,
      notComplete: notCompletedHours,
    };
  });

  const result: Array<Array<string | number | object>> = [
    ['Day', 'Completed', 'Not completed'],
  ];

  data.forEach((d, i) => {
    if (i === 0) {
      result.push(['Sun', d.complete, d.notComplete]);
    } else if (i === 1) {
      result.push(['Mon', d.complete, d.notComplete]);
    } else if (i === 2) {
      result.push(['Tue', d.complete, d.notComplete]);
    } else if (i === 3) {
      result.push(['Wed', d.complete, d.notComplete]);
    } else if (i === 4) {
      result.push(['Thr', d.complete, d.notComplete]);
    } else if (i === 5) {
      result.push(['Fri', d.complete, d.notComplete]);
    } else if (i === 6) {
      result.push(['Sat', d.complete, d.notComplete]);
    }
  });

  // #00af74 #cfd7d5

  return {
    data: result,
    options: {
      legend: { position: 'top', maxLines: 3 },
      isStacked: true,
      colors: [ '#00af74', '#cfd7d5' ],
      height: 300,
    },
  };
};

export const getCompletionByGroupData = (tasksByGroup: Array<{ channel: ChannelResponse, reports: DailyReportResponse[] }>, allTasks: DailyReportResponse[], startDate: string, endDate: string) => {
  const header: Array<string | number> = ['Date', 'All members'];

  const dateDiff = dayjs(endDate).diff(dayjs(startDate), 'day') + 1;

  const data = [...Array(dateDiff)].map((emp, i) => {
    const date = dayjs(startDate).add(i, 'day').format('YYYY-MM-DD');
    const r: Array<string | number> = [dayjs(date).format('MM/DD')];

    const allMemberTasks = allTasks.filter((task) => task.date === date);
    const allMemberCompletion = calcDoneRate(allMemberTasks) * 100;
    r.push(allMemberCompletion);

    tasksByGroup.forEach((taskByGroup) => {
      const memberTasks = taskByGroup.reports.filter((task) => task.date === date);
      const memberCompletion = calcDoneRate(memberTasks) * 100;
      r.push(memberCompletion);

      if (i === 0) {
        header.push(taskByGroup.channel.channelName);
      }
    });

    return r;
  });

  const result: Array<Array<string | number | object>> = [];
  result.push(header);

  data.forEach((d) => result.push(d));

  return {
    data: result,
    options: {
      legend: {
        position: 'bottom',
        textStyle: {
          fontSize: 12,
        },
      },
      vAxis: {
        0: {
          title: 'Completion',
          maxValue: 100,
          format: 'percent',
        },
      },
      hAxis: {
        title: 'Date',
      },
      height: 400,
    },
  };
};

export default {
  // common
  error: 'An error occured.',
  team: 'Team',
  save: 'Save',
  plan: 'Plan',
  title: 'Title',
  email: 'Email',
  password: 'Password',
  passwordConfirm: 'Password (confirm)',
  cancel: 'Cancel',
  invite: 'Invite',
  tags: 'Tags',
  users: 'Users',
  user: 'User',
  edit: 'Edit',
  delete: 'Delete',
  add: 'Add',
  today: 'Today',
  todaysTask: 'Today\'s task',
  journal: 'Journal',
  allUsers: 'All members',
  groups: 'Groups',
  talkroom: 'Talkroom',
  stats: 'Stats',
  myStats: 'My stats',
  teamStats: 'Team stats',
  tagAnalysis: 'Tag analysis',
  weeklyReports: 'Reports',
  roadmaps: 'Roadmaps',
  taskpool: 'Taskpool',
  settings: 'Settings',
  userSettings: 'User settings',
  teamSettings: 'Team settings',
  inviteUser: 'Invite members',
  logout: 'Logout',
  calendar: 'Clendar',
  limitation: 'limitation is',
  subtask: 'Subtask',
  overUsersLimit: 'The number of users in your team is over the limit of your plan. Please update your plan or remove users.',
  goToTeamSetting: 'Go to team setting',
  contactAdmin: 'Contact your team admin and upgrade your plan.',
  or: 'or',
  menu: {
    changeTeam: 'Change team',
  },
  // auth
  signin: 'Sign in',
  signup: 'Sign up',
  loginWithSocialAccount: 'Sign in with social account',
  forgetPassword: 'Forgot password',
  currentPassword: 'Current Password',
  newPassword: 'New Password',
  newPasswordConfirm: 'New Password (confirm)',
  // task
  tasks: 'Tasks',
  taskAndEmotion: 'Register your tasks and emotion!',
  todaysEmotion: 'How are you feeling?',
  taskName: 'Task Name',
  tag: 'Tag',
  requiredTime: 'Required Time',
  estimate: 'Estimate',
  dragAndDrop: 'Change order by drag and drop',
  selectTag: 'Select a tag',
  select: 'select',
  selected: 'selected',
  deselect: 'deselect',
  moveToAnother: 'Move to another day',
  copied: 'Already copied',
  copyToAnother: 'Copy to another day',
  total: 'Total',
  newTask: 'New Task',
  cantEdit: 'You cannot edit past tasks',
  todaysTaskAndEmotion: 'Register your tasks and emotion!',
  moveTask: 'Move task',
  copyTask: 'Copy task',
  takeSnapshot: 'Take a snapshot',
  shareSetting: 'Change showing photo',
  interval: 'Change snapshot interval',
  filter: 'Change snapshot filter',
  task: 'Today\'s Task Management',
  suggestedTask: 'Suggested Task',
  getEventsFromCalendar: 'Get events from Google Calendar',
  eventsRegisteredAtCalendar: 'Events registered in Google Calendar',
  noCalendar: 'No calendar registered',
  noEventToday: 'No event registered in this calendar',
  addToTask: 'Add to Today\'s task',
  // task
  chatPlaceholder: 'How\'s it going?',
  chatTip: 'Press ⌘ / ctrl + return to send',
  chatExplain: 'Chats remain only for next 24 hours.',
  // daily note
  dailyReport: 'Daily report',
  dailyReports: 'Daily reports',
  dailyReportsSaved: 'Daily report has been successfully saved.',
  // team setting
  teamSetting: 'Team setting',
  setTimezone: 'Set your timezone.',
  teamId: 'Team ID',
  timezone: 'Timezone',
  notifications: 'Notifications',
  notificationSettings: 'Notifications',
  maxUsers: 'Maximum users',
  pricePerUser: 'Price per user',
  // user invite
  noAdmin: 'User does not have the valid privilege.',
  invited: 'Invited users.',
  inviteFailed: 'Failed to invite users.',
  inviteMessage: 'Input email address you want to invite',
  resentInvitation: 'Resent invitation email.',
  // user management
  userManagement: 'User management',
  resend: 'Resend',
  suspend: 'Suspend',
  suspendAccount: 'Suspend Account',
  suspendMessageEn: 'Are you sure to suspend',
  suspendMessageJa: '?',
  whitelistSetting: 'Email whitelist setting for invitation link',
  addWhitelist: 'Add email domain',
  whitelistSettingExplain: 'Add email domain to restrict self-signup users from invitaiton link.',
  teamMember: 'Team member',
  memberSetting: 'Member setting',
  // user setting
  userUpdated: 'The user information has been successfully updated.',
  userSettingUpdated: 'User setting has been updated.',
  newScheduledTask: 'New scheduled task',
  scheduledTask: 'Scheduled task',
  emailNotification: 'Email notification',
  userSetting: 'User Setting',
  changeImage: 'Change Profile Image',
  userName: 'User Name',
  firstName: 'First Name',
  lastName: 'Last Name',
  language: 'Language',
  teamPage: 'Return to Team Page',
  pushNotificationSetting: 'Push Notification Setting',
  chatNotification: 'New chat arrived',
  taskIncompleteNotification: 'Unfinished tasks remain (The notification time can be set by the administrator)',
  taskNoRegistNotification: 'No task registered (The notification time can be set by the administrator)',
  teamMemberActive: 'Team member login',
  teamMemberTaskDone: 'Team member task completion',
  teamMemberJournalCreated: 'Team member journal',
  isAddedToChannel: 'Added to channel',
  suggestedToPoolTask: 'Suggested to pool task',
  takenPoolTask: 'My pool task taken',
  googleCalendarSetting: 'Google Calendar Setting',
  linkGoogleCalendar: 'Link Google Calendar',
  pleaseSigninWithGoogle: 'Please sign in with Google account.',
  profileSetting: 'Profile Image Setting',
  ok: 'OK',
  // plan setting
  planSetting: 'Plan setting',
  proPlanDescription: '✅ Unlimited team members / ✅ Unlimited data storage',
  planSet: 'Plan setting was successfully completed.',
  cardRegistered: 'Credit card was successfully registered.',
  bankRegistered: 'Bank transfer setting was successfully completed.',
  paymentStopped: 'Stopped payment.',
  currentPlan: 'Plan Information',
  monthly: 'Monthly',
  month: 'month',
  sixMonthes: 'Six monthes',
  changePlan: 'Change Plan',
  paymentSetting: 'Payment Setting',
  creditcard: 'Creditcard',
  currentCreditcard: 'Your current creditcard',
  billMessage: 'If you need a bill, please let us know from the chat window on the lower-right.',
  changeCard: 'Change creditcard',
  registerCard: 'Register creditcard to apply the plan.',
  isTrial: 'Now in free trial.',
  freePlan: 'Free plan',
  downgrade: 'Downgrade',
  limitedUsers: 'Up to 5 members',
  donwgradeMessage: 'To dongrade, remove team members to 5 or less',
  limitedData: 'Stats for last 2 weeks',
  currentPlanPrefix: 'Your current plan is',
  howToUpgradeToV2Plan: 'To upgrade to new plan, please contact via chat in right below.',
  // notify setting
  channel: 'Channel',
  message: 'Message',
  notificationTime: 'Notification Time',
  loginReminder: 'Login Reminder',
  loginNotification: 'Login Notification',
  weeklyReport: 'Weekly Report',
  taskRegistrationReminder: 'Reminder of Task Registration',
  taskCompletionReminder: 'Reminder of Task Completion',
  loginReminderMessage: 'Send reminder messages of login to all members of the team at the specific time every day.',
  loginNotificationMessage: 'Send notification of a user\'s login to a specific channel.',
  weeklyReportMessage: 'Send a weekly stats report to the administration users on Mondays.',
  taskRegistrationReminderMessage: 'Send reminder messages to users who have not registered any tasks.',
  taskCompletionReminderMessage: 'Send reminder messages to users who have incompleted tasks.',
  unlinkSlack: 'Unlink Slack',
  linkSlack: 'Link Slack',
  unlinkChatwork: 'Unlink Chatwork',
  linkChatwork: 'Link Chatwork',
  // tag setting
  tagSetting: 'Tag Setting',
  archivedTags: 'Archived tags',
  archive: 'Archive',
  activate: 'Activate',
  addTag: 'Add Tag',
  deleteTag: 'Delete Tag',
  deleteMessageEn: 'Are you sure to delete',
  deleteMessageJa: '?',
  // stats
  period: 'Showing Period',
  has: 'has',
  days: 'days',
  unlimited: 'unlimited',
  storagePeriod: 'storage period',
  yourStats: 'Your stats',
  weeklyReportsTitle: 'Weekly reports',
  weeklyReportsNotFound: 'No reports available',
  emailSentCSV: 'CSV data was sent to the registered email address.',
  sStats: '\'s Stats',
  totalTasks: 'Total Tasks',
  totalHours: 'Total hours',
  completedTasks: 'Completed Tasks',
  completionRate: 'Completion',
  numberOfTasks: 'Number of tasks',
  combinationChart: 'Combination',
  onlyCompletionRate: 'Completion',
  taskAnalysisByTag: 'Task Analysis by Tag',
  noTagInStats: 'No tags registerd.',
  averageHour: 'Average Task Time',
  requiredTimeRate: 'Required Time Rate',
  showWeekends: 'Show weekends',
  searchByUserName: 'Search by user name',
  seeMore: 'See more',
  close: 'Close',
  highCompletionRate: 'High Completion',
  longRequiredTime: 'Long Required Time',
  longDoneTime: 'Long Completion Time',
  doneTime: 'Completion Time',
  taskDistribution: 'Task Distribution to users',
  downloadCSV: 'Download CSV data',
  legend: 'Legend',
  userAnalysis: 'User analysis',
  sort: 'Sort',
  displayTags: 'Tags to display',
  // auth
  passwordChanged: 'Password was successfully changed.',
  passwordChangeFailed: 'Failed to change password.',
  joinTeam: 'Join Team',
  sentInvitation: 'Invitation email was sent.',
  resetPassword: 'Reset password',
  passwordResetCompleted: 'Your password is successfully updated',
  sendPasswordResetEmail: 'Send link',
  resetEmailSent: 'Email was sent to your email address.',
  selectTeam: 'Select team',
  signupTitle: 'Create team',
  createTeam: 'Create team',
  teamIdRule: 'Please input using half-width characters or hyphen',
  teamName: 'Team Name',
  agreement: 'I agree to the remonade',
  tos: 'Terms of Service',
  and: 'and',
  privacyPolicy: 'Privacy Policy',
  agreementEnd: '',
  confirmationEmailSent: 'Confirmation email was sent to your email address.',
  validator: {
    required: 'This item is required.',
    teamId: 'Only half-width characters or hyphen allowed',
    email: 'This email address is invalid',
    passwordLength: 'Use more than 8 characters',
    passwordChars: 'Use all of capital and small letters and number',
    passwordConfirm: 'The two passwords are not same.',
  },
  // onboarding
  welcome: 'Welcome to remonade',
  letsInvite: 'At first, you can invite your team members to remonade.',
  registerTag: 'Next, you can register tags.',
  tagMessage: 'It is effective to create tags related to projects or missions that your team is working on.',
  analysisMessage: 'Tags will be used for task analysis.',
  tagName: 'Tag Name',
  register: 'Register',
  copyURL: 'Copy invitation URL',
  invitedTeamMembers: 'Invited team members.',
  registeredTask: 'Registered tasks.',
  copiedInvitationLink: 'Successfully copied invitation URL. People can sign up to your team at this URL.',
  copyFailed: 'Failed to copy invitation URL.',
  dailyTask: 'Daily Task',
  // talkroom
  talkroomTitle: 'Edit talkrooms',
  createTalkroom: 'Create new talkroom',
  addTalkroom: 'Add talkroom',
  privateMode: 'Private mode',
  roomName: 'New talkroom name',
  create: 'Create',
  current: 'Your current',
  talkroomLimitation: 'has a limitation of',
  talkroomDesu: 'talkrooms.',
  member: 'Member',
  remove: 'Remove',
  addUser: 'Add user',
  deleteTalkroom: 'Delete talkroom',
  deleteTalkroomMemberMessage: 'Are you sure to remove this member from the talkroom?',
  deleteTalkroomMessage: 'Are you sure to delete this talkroom?',
  thisIsPublic: 'This is a public talkroom',
  // taskpool
  taskpoolTitle: 'Taskpool',
  newTaskPool: 'New task',
  hour: 'Hour',
  taskname: 'Task',
  addSuggest: 'Add suggestion',
  pool: 'Pool',
  takeit: 'Move to today\'s task',
  take: 'Take',
  noTag: 'No tag',
  // team
  editGroup: 'Edit Group',
  group: {
    addUserToGroup: 'Add members',
    groupSetting: 'Group Setting',
    groupName: 'Group Name',
  },
  createGroup: 'Create new group',
  addGroup: 'Add group',
  groupName: 'New group name',
  groupLimitation: 'has a limitation of',
  groupsDesu: 'groups.',
  deleteGroup: 'Delete group',
  deleteGroupMemberMessage: 'Are you sure to remove this member from the group?',
  deleteGroupMessage: 'Are you sure to delete this group?',
  inviteByEmail: 'Invite by email',
  inviteByURL: 'Invite by URL',
  memberLimitation: 'has a limitation of',
  membersDesu: 'members.',
  more: 'In order to invite more users, ',
  changePlanMessage: 'please upgrade plan.',
  please: '',
  userMaxMessage: 'In order to invite more users, please upgrade plan.',
  statsPeriodMessage: 'In order to see stats for more than 14 days ago, please upgrade plan.',
  upgrade: 'Upgrade',
  upgradeMessage: 'In order to invite more users, please upgrade your plan.',
  emailInvalidAlertPre: 'Email address',
  emailInvalidAlertSuf: 'is invalid.',
  emailMineAlertPre: 'Email address',
  emailMineAlertSuf: 'is yours.',
  // bookmark
  bookmark: {
    title: 'Bookmark',
    createTitle: 'New bookmark',
    editTitle: 'Edit bookmark',
  },
  // error messages
  errorUnexpected: 'Unexpected error occured.',
  errorAuthAlert: 'Verification error occured, please try again.',
  errorInviteAlert041: 'Your team reached to member limit. Please contact administrator and upgrade plan.',
  errorSignin: 'User information does not match any account.',
  errorSignup: 'Registration error occured, please try again.',
  errorE003: 'Team does not exist.',
  errorE005: 'Missing some required params.',
  errorE007: 'User does not exist.',
  errorE020: 'Invalid email address provided.',
  errorE032: 'Password provided does not satisfy format.',
  errorE038: 'Username already exists.',
  errorE039: 'Invalid verification code provided, please try again.',
  errorE040: 'Verification code has been expired.',
  errorE041: 'Password does not conform to policy.',
  errorE042: 'Only half-width alphanumeric and hyphen are allowed to create team ID.',
  errorE043: 'Team ID already exists.',
  errorE051: 'Payment method not registered.',
  errorE075: 'Email is not verified',
  errorE076: 'Plan has not changed',
  errorE077: 'Unable change plan from an annual payment to a monthly payment. Contact support.',
  errorE082: 'Unable to change the plan because the team size is over the limit.',
  errorE083: 'Unable to change the plan because the user groups are over the limit.',
  errorE088: 'Unable to change the plan because the talkrooms are over the limit.',
  errorE089: 'Unable to change payment currency.',
};
